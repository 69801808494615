<template>
  <div class="builder">
    <h3 v-html="genome"></h3>
    <div class="columns">
      <div class="left">
        <div v-for="(trait, index) in traitMap" :key="`trait-${index}`" class="trait-builder">
          <h3>
            {{ trait.name || 'Trait Name' }}
            <div class="color-marker" :style="`background-color: ${traitColors[index]}`"></div>
          </h3>
          <h4>Max Variations: {{ 16 ** trait.numBases }}</h4>
          <TextInput v-model="trait.name" fieldName="trait-name" label="Trait Name" small />
          <v-select v-model="trait.type" label="Trait Type" :items="traitTypes"></v-select>
          <div class="field-row">
            <TextInput v-model="trait.numBases" fieldName="num-bases" label="Number of Bases" small type="number" />
            <TextInput v-model="trait.activePreview" fieldName="active-preview" label="Active Preview" small type="number"/>
          </div>
          <div class="variations">
            <h4>Variations Map</h4>
            <div v-for="(variation, j) in trait.variations" :key="`variation-${index}-${j}`" class="field-row">
              <TextInput v-model="variation.name" fieldName="variation-name" label="Name" small />
              <TextInput v-model="variation.url" fieldName="variation-url" label="URL" small />
            </div>
            <button class="standard small" @click="addVariation(index)">Add Variation</button>
          </div>
        </div>

        <button class="standard" @click="addTrait">Add Trait</button>
      </div>
      <div class="right">
        <h3>Trait Map</h3>
        <div class="code-container">
          <pre>{{ JSON.stringify(computedTraits, undefined, 2) }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      traitTypes: ['model', 'animation', 'texture', 'audio'],
      colors: ['firebrick', 'green', 'lightblue', 'yellow', 'orange', 'pink'],
      traitMap: [
        {
          name: 'Body',
          type: 'model',
          numBases: 1,
          variations: [
            { name: 'sleepy', url: '' }
          ]
        },
        {
          name: 'Pattern',
          type: 'texture',
          numBases: 1,
          variations: []
        }
      ]
    }
  },
  computed: {
    genome () {
      let output = '0x'
      this.traitMap.forEach((trait, index) => {
        const activeIndex = trait.activePreview || 0
        const hex = Number(activeIndex).toString(16).padStart(trait.numBases, '0')
        output += `<span style="color: ${this.traitColors[index]}">[</span>${hex}<span style="color: ${this.traitColors[index]}">]</span>`
      })
      return output
    },
    computedTraits () {
      const output = this.traitMap
      let currentIndex = 0
      output.forEach((trait, index) => {
        delete trait.activePreview
        if (trait.numBases > 1) {
          trait.dnaIndex = [currentIndex]
          currentIndex += Number(trait.numBases)
          trait.dnaIndex.push(currentIndex - 1)
        } else {
          trait.dnaIndex = currentIndex
          currentIndex++
        }
      })
      return output
    },
    traitColors () {
      const colors = []
      this.traitMap.forEach((trait, index) => {
        colors.push(this.colors[index % this.colors.length])
      })
      return colors
    }
  },
  methods: {
    addTrait () {
      this.traitMap.push({
        name: '',
        type: '',
        numBases: 1,
        variations: []
      })
    },
    addVariation (index) {
      this.traitMap[index].variations.push({
        name: '',
        url: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.builder {
  h4 {
    margin-bottom: $space-s;
  }
  .field-container {
    margin-bottom: $space-s;
  }
  .columns  {
    display: flex;
    gap: $space-m;
    > div {
      flex: 1;

      &.right {
        .code-container {
          overflow: scroll;
          max-height: 400px;
          border: solid 1px $blk-grey-5;
        }
      }
    }
  }

  .trait-builder {
    border-bottom: solid 1px $blk-white;
    margin-bottom: $space-l;
    padding: $space-m 0;

    .color-marker {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
  .field-row {
    display: flex;
    gap: $space-s;
    .field-container {
      flex: 1;
    }
  }
}
</style>
